import { PageProps } from "gatsby";
import React from "react";

import { SoftwareMuseScore } from "../components/pages/SoftwareMuseScore";

import { snProps } from "../js/utils";

const SoftwareMusescorePage = (props: PageProps) => (
  <SoftwareMuseScore {...{ ...snProps, ...props }} />
);

export default SoftwareMusescorePage;
