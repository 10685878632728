import React from "react";
import { Link, withPrefix } from "gatsby";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";

// @ts-ignore
import MuseScoreClairnoteScreenshot624x461 from "../../images/png/musescore-clairnote-screenshot-624x461.png";
// @ts-ignore
import MuseScoreClairnoteScreenshot from "../../images/png/musescore-clairnote-screenshot.png";

// @ts-ignore
import MuseScoreStafftypeTemplateScreenshot from "../../images/png/musescore-stafftype-template-screenshot.png";
// @ts-ignore
import MuseScoreStafftypeTemplateScreenshot624x530 from "../../images/png/musescore-stafftype-template-screenshot-624x530.png";

import * as pageStyles from "../global-styles/page.module.css";

export const SoftwareMuseScore = (props: PageComponentProps) => {
  const title = "Software: MuseScore";
  const description = `An experimental open-source version of MuseScore (music notation software) for editing sheet music in ${props.clairnoteName} music notation.`;
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <div>
          {props.sn ? (
            <>
              <p>
                Currently the custom{" "}
                <Link to={props.otherUrlDir + "software-musescore/"}>
                  Clairnote version of MuseScore
                </Link>{" "}
                only supports <Link to={props.otherUrlDir}>Clairnote DN</Link>{" "}
                and not <Link to={props.urlDir}>Clairnote SN</Link>.
              </p>
            </>
          ) : (
            <>
              <p>
                A lot of work has been done to create a version of{" "}
                <a
                  href="https://musescore.org/"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  MuseScore
                </a>{" "}
                that supports {props.clairnoteName} (along with other
                alternative notation systems). Many thanks to Craig Fisher who
                took this on and has done some great work, putting in a lot of
                time and effort. The project's code lives in his{" "}
                <a
                  href="https://github.com/CraigFisher/MuseScore/"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  GitHub repo
                </a>{" "}
                and he has also{" "}
                <a
                  href="https://musicrewritten.wordpress.com/"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  blogged
                </a>{" "}
                about it. Ole Kirkeby has also contributed.
              </p>
              <p>
                The new features have not been, and will likely not be, added to
                the official MuseScore app, given the stance of the MuseScore
                developers on this kind of thing. So we are talking about a
                specialized/modified version of MuseScore. It's still in
                development, but you can already install it and try things out.
              </p>
              <div className={pageStyles.imageContainer}>
                <figure className={pageStyles.thumbnailImageContainer}>
                  <a
                    href={MuseScoreClairnoteScreenshot}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ marginTop: "0em" }}
                      src={MuseScoreClairnoteScreenshot624x461}
                      alt={
                        "Screenshot of " + props.clairnoteName + " in MuseScore"
                      }
                    />
                  </a>
                  <figcaption className={pageStyles.caption}>
                    {props.clairnoteName} in MuseScore (Click to Enlarge)
                  </figcaption>
                </figure>
              </div>
              <p>
                While it's still a work in progress, the most basic elements of{" "}
                {props.clairnoteName} are already implemented (the chromatic
                staff, ledger lines, the{" "}
                <Link to={props.urlDir + "staff/#six-six"}>6-6</Link> notehead
                pattern, etc.). You can create {props.clairnoteName} scores from
                scratch or convert existing traditional scores to{" "}
                {props.clairnoteName} and back. Some items still to be
                implemented are double stems to indicate half notes, correct
                stem lengths, and {props.clairnoteName}
                's accidental signs and key signatures.
              </p>
              <p>
                If you want to try out the modified version of MuseScore, the
                system{" "}
                <a
                  href="https://musescore.org/en/download#Minimum-requirements"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  requirements
                </a>{" "}
                are the same as for MuseScore 2.x.x. You will need to{" "}
                <a
                  href="https://musescore.org/en/developers-handbook/compilation"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  compile
                </a>{" "}
                the source code that is available from the{" "}
                <a
                  href="https://github.com/CraigFisher/MuseScore/"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  GitHub repo
                </a>
                . (Be forewarned that compiling your own binary app requires a
                non-trivial level of technical know-how.) You will also need the
                following "staff type template" file (an XML file format) that
                contains the {props.clairnoteName} settings to load into
                MuseScore:{" "}
                <a href={withPrefix("musescore/Clairnote.stt")}>
                  Clairnote.stt
                </a>
              </p>
              <div className={pageStyles.imageContainer}>
                <figure className={pageStyles.thumbnailImageContainer}>
                  <a
                    href={MuseScoreStafftypeTemplateScreenshot}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      style={{ marginTop: "0em" }}
                      src={MuseScoreStafftypeTemplateScreenshot624x530}
                      alt="MuseScore stafftype template screenshot"
                    />
                  </a>
                  <figcaption className={pageStyles.caption}>
                    StaffType Templates (Click to Enlarge)
                  </figcaption>
                </figure>
              </div>
              <p>
                Please <Link to={props.urlDir + "contact/"}>contact us</Link> if
                you have questions about any of this.
              </p>
              <p>
                While <Link to={props.urlDir + "software/"}>LilyPond</Link>{" "}
                produces excellent results and is much more feature-complete in
                terms of supporting {props.clairnoteName}, MuseScore offers the
                kind of user-friendly graphical user interface that most people
                expect. Subscribe to the{" "}
                <Link to={props.urlDir + "newsletter/"}>
                  Clairnote Newsletter
                </Link>{" "}
                to stay informed about progress on support for{" "}
                {props.clairnoteName} in MuseScore.
              </p>
            </>
          )}
        </div>
      </article>
    </Layout>
  );
};
